<script setup lang="ts">
import type { ReservationAgency } from '~/components/organisms/VReservationForm/VReservationForm.vue'

interface ReservationRangePickerProps {
    agency?: ReservationAgency
    modelValue: Date[]
    disabled?: boolean
}

const props = defineProps<ReservationRangePickerProps>()

const emit = defineEmits(['update:modelValue'])
const internalModelValue = computed({
    get: () => props.modelValue || [],
    set: (value: Date[] | null) => emit('update:modelValue', value || []),
})
</script>

<template>
    <VReservationDateTimeInput
        v-for="index in 2"
        :key="index"
        v-model="internalModelValue"
        required
        :mode="index === 1 ? 'start' : 'end'"
        :disabled="disabled"
        :agency="agency"
    />
</template>
