import type { FetchError } from 'ofetch'
import type { JsonLdCollection, VehicleType } from '~/types/api'
import type { _AsyncData } from '#app/composables/asyncData'

type DataResponse = JsonLdCollection<VehicleType>

type FetchResponse = Pick<
    _AsyncData<DataResponse | null, FetchError>,
    'data' | 'error'
>

export async function useAvailableVehicleTypes() {
    const vehicleTypeResponse = useState<FetchResponse | undefined>('vehicle_types_response', () => undefined)

    if (!vehicleTypeResponse.value?.data) {
        const { $apiFetch } = useNuxtApp()

        const { data, error } = (await useAsyncData('vehicle_types', async () => {
            return $apiFetch<DataResponse>('/vehicle_types', {
                method: 'GET',
                query: {
                    'order[position]': 'asc',
                    'properties[0]': 'mrcTypeCode',
                    'properties[1]': 'id',
                    'properties[2]': 'title',
                    'properties[3]': 'image',
                },
            })
        }, { deep: false }))

        vehicleTypeResponse.value = { data, error }
    }

    const vehicleType = computed(() => {
        return toValue(vehicleTypeResponse.value?.data)?.['hydra:member']
    })

    const error = computed(() => {
        return toValue(vehicleTypeResponse.value?.error)
    })

    return {
        vehicleType,
        error,
    }
}
