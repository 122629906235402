import type { FetchError } from 'ofetch'
import type { Agency, JsonLdCollection } from '~/types/api'
import type { _AsyncData } from '#app/composables/asyncData'

type DataResponse = JsonLdCollection<Agency>

type FetchResponse = Pick<
    _AsyncData<DataResponse | null, FetchError>,
    'data' | 'error'
>

export async function useAvailableAgencies() {
    const agenciesResponse = useState<FetchResponse | undefined>('agencies_response', () => undefined)

    if (!agenciesResponse.value?.data) {
        const { $apiFetch } = useNuxtApp()

        const { data, error } = (await useAsyncData('agencies', async () => {
            return $apiFetch<DataResponse>('/agencies', {
                method: 'GET',
                query: {
                    'exists[openingHoursSpecification]': true,
                    'order[slug]': 'ASC',
                    'properties[0]': 'mrcAgencyCode',
                    'properties[1]': 'id',
                    'properties[2]': 'title',
                    'properties[3]': 'timezone',
                    'properties[4]': 'minimumDepartureHoursDelay',
                    'properties[5]': 'minimumReturnHoursDelay',
                    'properties[6]': 'maximumDepartureDaysDelay',
                    'properties[7]': 'maximumReturnDaysDelay',
                    'properties[8]': 'openingHoursSpecification',
                    'properties[9]': 'location',
                },
            })
        }, { deep: false }))

        agenciesResponse.value = { data, error }
    }

    const agencies = computed(() => {
        return toValue(agenciesResponse.value?.data)?.['hydra:member']
    })

    const error = computed(() => {
        return toValue(agenciesResponse.value?.error)
    })

    return {
        agencies,
        error,
    }
}
