<script lang="ts" setup>
interface DialogProps {
    label?: string | undefined
    confirmLabel?: string | undefined
    noAction?: boolean
    displayHeader?: boolean
    centered?: boolean
}

withDefaults(defineProps<DialogProps>(), {
    confirmLabel: 'Select',
    displayHeader: true,
    centered: true,
})

const isOpen = defineModel<boolean>({ default: false })

const root = ref<HTMLDialogElement>()

// const open = () => isOpen.value = true
function openModel() {
    root.value?.showModal()
}

const close = () => isOpen.value = false
function closeModal() {
    root.value?.close()
}

watch(isOpen, (value: boolean) => {
    if (value) openModel()
    else closeModal()
})

// USER INTERACTIONS
useEventListener(document, 'keyup', (event) => {
    if (event.key === 'Escape') {
        event.preventDefault()
        close()
    }
})

function onClick(event: MouseEvent) {
    if (!root.value) return

    const rect = root.value.getBoundingClientRect()

    if (
        event.clientY < rect.top
        || event.clientY > rect.bottom
        || event.clientX < rect.left
        || event.clientX > rect.right
    ) {
        close()
    }
}

const $style = useCssModule()
const rootClasses = computed(() => {
    return [$style.root, isOpen.value && $style['root--open']]
})
</script>

<template>
    <dialog
        ref="root"
        :inert="!isOpen"
        :class="rootClasses"
        @click="onClick"
    >
        <div
            v-if="displayHeader"
            :class="$style.header"
        >
            <span
                v-if="label"
                class="text-h2-sm cap-black-italic"
                :class="$style.title"
            >
                {{ label }}
            </span>
            <slot name="before-close" />
            <VButton
                :class="$style.close"
                :aria-label="$t('close')"
                outlined
                size="s"
                autofocus
                icon-name="close-small"
                @click="close"
            />
        </div>
        <slot />
        <VButton
            v-if="!noAction"
            :class="$style.confirm"
            size="xl"
            filled
            :label="confirmLabel"
            @click="close"
        />
    </dialog>
</template>

<style lang="scss" module>
.root {
    &--open {
        display: flex;
        width: 100%;
        max-width: initial;
        flex-direction: column;
        padding: 0;
        border: 1px solid rgb(1 1 1 / 20%);
        border-radius: var(--radius-md) var(--radius-md) 0 0;
        margin: auto auto 0;
        background-color: color(white);

        @include media('>=md') {
            width: clamp(#{rem(650)}, 50vw, #{rem(850)});
            border-radius: var(--radius-md);
            margin: auto;
        }
    }

    &::backdrop {
        background-color: rgb(0 0 0 / 60%);
        cursor: pointer;
    }

}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(24) rem(24) rem(20);
    border-bottom: 1px solid var(--color-line-primary, rgb(1 1 1 / 10%));
    gap: rem(24);

    @include media('>=md') {
        padding: rem(28) rem(24) rem(24) rem(48);
    }
}

.title {
    flex-grow: 1;
    margin: 0;
}

.confirm {
    align-self: flex-end;
    margin: var(--spacing-sm) var(--spacing-md);
}

.close {
    height: 32px;
    border: 1px solid var(--color-line-primary, rgb(1 1 1 / 15%));
}
</style>
