import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "1.5",
      d: "M8.889 8.111V5zm6.222 0V5zm-7 3.111h7.778zM6.556 19h10.888C18.3 19 19 18.3 19 17.444V8.111c0-.855-.7-1.555-1.556-1.555H6.556C5.7 6.556 5 7.256 5 8.11v9.333C5 18.3 5.7 19 6.556 19"
    }, null, -1)
  ])))
}
export default { render: render }