<script setup lang="ts">
export interface VDialogListOption {
    value: string
    label: string
}

export interface VDialogListProps {
    label: string | undefined
    options: VDialogListOption[] | undefined
}

defineProps<VDialogListProps>()

const isOpen = defineModel<boolean>('isOpen', { default: false })
const value = defineModel<string | undefined>('value')
</script>

<template>
    <VDialog
        v-model="isOpen"
        :label="label"
        no-action
        @close="isOpen = false"
    >
        <template #before-close>
            <slot name="before-dialog-close" />
        </template>
        <ul :class="$style.list">
            <li
                v-for="option in options"
                :key="option.value"
                :class="[$style.item, option.value === value && [$style['item--selected']]]"
            >
                <button
                    :class="$style.item__button"
                    @click.prevent="value = option.value"
                >
                    {{ option.label }}
                    <slot
                        name="icon"
                        :selected="option.value === value"
                    />
                </button>
            </li>
        </ul>
    </VDialog>
</template>

<style lang="scss" module>
.list {
    display: flex;
    max-height: 50vh;
    flex-direction: column;
    padding: var(--spacing-2-xs) var(--spacing-sm);
    margin-bottom: 24px;
    gap: var(--spacing-xs);
    list-style: none;
    overflow-y: auto;

    @include media('>=md') {
        max-height: min(45vh, #{rem(600)});
        padding: rem(24) rem(48);
    }

}

.item__button {
    @include text-button-m;

    display: flex;
    width: 100%;
    height: rem(40);
    align-items: center;
    justify-content: space-between;
    border: 1px solid transparent;
    border-radius: var(--radius-sm);
    background-color: var(--color-surfaces-secondary);
    color: var(--color-primary);
    padding-inline: rem(12) rem(8);
    transition: border-color 0.2s;
    user-select: none;

    .item--selected & {
        background-color: var(--color-primary);
        color: var(--color-surfaces-secondary);
    }

    @media (hover: hover) {
        &:hover {
            border-color: color(black);
        }
    }
}
</style>
