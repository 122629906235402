<script lang="ts" setup>
import type { VDialogListOption } from '~/components/organisms/VDialogList/VDialogList.vue'

export interface VSelectProps<T = string> {
    id?: string
    label?: string
    dialogTitle?: string
    name?: string
    modelValue: T
    error?: string
    loading?: boolean
    required?: boolean
    disabled?: boolean
    placeholder?: string
    closeOnSelect?: boolean
    icon?: string
    options?: VDialogListOption[] | (() => Promise<VDialogListOption[]>)
}

const props = withDefaults(defineProps<VSelectProps>(), {
    closeOnSelect: true,
})

const selectElement = ref<HTMLSelectElement | undefined>()
const dialogOpen = ref(false)

const value = defineModel<string | undefined>()

watch(value, () => {
    if (props.closeOnSelect) {
        dialogOpen.value = false
    }
})

const onMouseDown = (event: MouseEvent) => {
    if (window && window.matchMedia('(max-width: 767px)').matches) {
        return
    }

    event.preventDefault()

    dialogOpen.value = true
}

function onKeyDown(event: KeyboardEvent) {
    switch (event.key) {
        case 'Enter':
        case ' ':
        case 'ArrowUp':
        case 'ArrowDown':
            event.preventDefault()
            dialogOpen.value = true
            break
    }
}

watch(dialogOpen, (value) => {
    if (!value && selectElement.value) {
        // Keep focus on select element when dialog is closed
        selectElement.value.focus()
    }
})

const $style = useCssModule()

const internalOptions = ref<VDialogListOption[]>()
const fetchError = ref<string>()
const pending = ref(false)

async function setInternalOptions() {
    if (Array.isArray(props.options)) {
        internalOptions.value = props.options
    }
    else if (typeof props.options === 'function') {
        try {
            pending.value = true
            internalOptions.value = await props.options()
            pending.value = false
        }
        catch (error) {
            pending.value = false
            fetchError.value = (error as Error).message || 'An error occurred while fetching the options'
        }
    }
}

await setInternalOptions()
</script>

<template>
    <VInput
        :id="id"
        :required="required"
        :class="$style.root"
        v-bind="$attrs"
        :error="error || fetchError"
        :label="label"
        :disabled="disabled"
    >
        <div
            :class="[
                $style['select__wrapper'],
                disabled && $style['select__wrapper--disabled'],
                !value && $style['select__wrapper--placeholder'],
            ]"
        >
            <VIcon
                v-if="icon"
                :class="$style.icon"
                :name="icon"
            />
            <select
                :id="id"
                ref="selectElement"
                v-model="value"
                :class="[$style.select, value && $style['select--filled']]"
                :name="name"
                :required="required"
                :disabled="disabled"
                @keydown="onKeyDown"
                @mousedown="onMouseDown"
            >
                <option
                    v-if="placeholder"
                    :value="undefined"
                    disabled
                    selected
                >
                    {{ placeholder }}
                </option>
                <option
                    v-for="(option, i) in internalOptions"
                    :key="option.value + '-' + i"
                    :value="option.value"
                    :selected="option.value === value"
                >
                    {{ option.label }}
                </option>
            </select>
            <ClientOnly>
                <VDialogList
                    v-model:is-open="dialogOpen"
                    v-model:value="value"
                    :label="dialogTitle || label"
                    :options="internalOptions"
                >
                    <template #before-dialog-close>
                        <slot name="before-dialog-close" />
                    </template>
                </VDialogList>
            </ClientOnly>
        </div>
    </VInput>
</template>

<style lang="scss" module>
.root {
    --v-input-root-width: 100%;
}

.select__wrapper {
    position: relative;
    width: 100%;

    &--disabled {
        color: var(--color-primary-disabled);
        pointer-events: none;
    }
}

.icon {
    position: absolute;
    top: 50%;
    left: 0;
    width: 24px;
    height: 24px;
    margin-left: var(--spacing-2-xs);
    color: var(--color-content-secondary);
    pointer-events: none;
    transform: translateY(-50%);

    .select__wrapper--disabled & {
        color: inherit;
    }
}

.select {
    --v-input-padding: 0 calc(var(--spacing-2-xs) + 28px);

    &--filled {
        font-weight: 500;
    }

    &:not(:disabled) {
        cursor: pointer;
    }
}
</style>
