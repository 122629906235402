<script lang="ts" setup>
import type { Agency } from '~/types/api'
import type { VSelectProps } from '~/components/organisms/VInputSelectDialog/VInputSelectDialog.vue'
import VMapPageButton from '~/components/atoms/VMapPageButton/VMapPageButton.vue'

interface VAgencySelectProps extends VSelectProps {
    valueField?: 'mrcAgencyCode' | '@id' | 'id'
    agencies?: Agency[] | null
}

const props = withDefaults(defineProps<VAgencySelectProps>(), {
    valueField: 'mrcAgencyCode',
    placeholder: 'choose_an_agency',
})

const internalModelValue = defineModel<string | undefined>()

function getFormatAgenciesOption(agencies: Agency[] | undefined | null) {
    if (!agencies?.length) return undefined

    return agencies.map(agency => ({
        label: `${agency.title} (${agency.location?.address?.postalCode})`,
        value: agency[props.valueField].toLocaleLowerCase(),
    }))
}

const options = ref(getFormatAgenciesOption(props.agencies))

if (!options.value?.length) {
    const { agencies } = await useAvailableAgencies()
    options.value = getFormatAgenciesOption(agencies.value)
}
</script>

<template>
    <VInputSelectDialog
        :id="id"
        v-model="internalModelValue"
        :required="required"
        :error="error"
        :label="label"
        :placeholder="$t('choose_an_agency')"
        icon="pin"
        :options="options"
    >
        <template #before-dialog-close>
            <VMapPageButton hide-on-mobile />
        </template>
    </VInputSelectDialog>
</template>
